<template>
    <div>
        <br>        
        <v-progress-circular class="ml-12"
            :size="100"
            width="15"
            color="amber"
            indeterminate
            ></v-progress-circular>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
export default {

}
</script>

<style>
</style>